import { initialState } from "./initialState";
import {
  API_STATUS,
  GET_USER_LIST,
  SET_PRO_PROJECTS,
  GET_PRO_LIST,
  GET_PRO_DATA,
  CLEAR_API_STATUS,
  PDF_AND_PAGE_DETAILS,
  IS_SIGNATURE_UPLOADED_STATUS,
  SET_OCR_VALUE,
  VALIDATE_USER_STATUS,
  UPDATE_VIEW_STATUS,
  SET_SELECTED_FILES_DATA_FOR_PRO_UPDATE,
  GET_INFO_DATA,
  GENERATE_REPORT_REQUEST,
} from "./types";

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case API_STATUS:
      return {
        ...state,
        apiStatus: action.payload,
      };

    case GET_USER_LIST:
      return {
        ...state,
        userList: action.payload.data,
      };
    case GET_PRO_LIST:
      return {
        ...state,
        proProjects: action.payload,
      };
    case SET_PRO_PROJECTS:
      return {
        ...state,
        projects: [...action.payload],
      };
    case GET_PRO_DATA:
      return {
        ...state,
        coorData: action.payload,
      };
    case CLEAR_API_STATUS:
      return {
        ...state,
        apiStatus: action.payload,
      };
    case IS_SIGNATURE_UPLOADED_STATUS:
      return {
        ...state,
        isSignatureUploaded: action.payload,
      };

    case PDF_AND_PAGE_DETAILS: {
      return {
        ...state,
        pdfAndPageDetails: action.payload.data,
      };
    }

    case SET_OCR_VALUE:
      return {
        ...state,
        bindOcrValuesAndCoords: action.payload,
      };

      case SET_SELECTED_FILES_DATA_FOR_PRO_UPDATE:
        return {
          ...state,
          selectedFilesDataForProUpdate: action.payload,
        };

    case VALIDATE_USER_STATUS:
      return {
        ...state,
        validateUser: action.payload,
      };
    case UPDATE_VIEW_STATUS:
      return {
        ...state,
        projects: action.payload,
      };
      case GET_INFO_DATA:
        return {
          ...state,
          infoData: action.payload,
        };    
        
      case GENERATE_REPORT_REQUEST:
        return {
          ...state,
          qaReportResult: action.payload
        }

    default:
      return state;
  }
};

export default reducer;
