// for manage group
export const API_STATUS = "API_STATUS";
export const GET_GROUP_LIST = "GET_GROUP_LIST";
export const DELETE_GROUP = "DELETE_GROUP";
export const EDIT_GROUP = "EDIT_GROUP";
export const ADD_GROUP = "ADD_GROUP";
export const MANAGE_GROUP = "MANAGE_GROUP";

// for manage user
export const GET_USER_LIST = "GET_USER_LIST";
export const DELETE_USER = "DELETE_USER";
export const EDIT_USER = "EDIT_USER";
export const ADD_USER = "ADD_USER";

// for data deletion
export const GET_DELETED_DATA = "GET_DELETED_DATA";

// upload batch files
export const LIST_BOX_FILES = "LIST_BOX_FILES";
export const UPLOAD_BOX_FILES = "UPLOAD_BOX_FILES";
export const BATCH_TYPE = "BATCH_TYPE";
export const UPLOAD_DATA = "UPLOAD_DATA";
export const PRODUCT_TYPE = "PRODUCT_TYPE";
export const PRODUCT_SUB_FOLDER = "PRODUCT_SUB_FOLDER";

//Manage Output
export const GET_OUT_PUT_LIST = "GET_OUT_PUT_LIST";
export const GET_OUT_PUT_URL = "GET_OUT_PUT_URL";
export const REJECT_OUT_PUT_FILE = "REJECT_OUT_PUT_FILE";
export const SUBMIT_OUT_PUT_FILE = "SUBMIT_OUT_PUT_FILE";
export const SUBMIT_OUT_PUT_FILE_UPLOAD = "SUBMIT_OUT_PUT_FILE_UPLOAD";
export const REJECT_OUT_PUT_FILE_BATCH = "REJECT_OUT_PUT_FILE_BATCH";
export const POST_PROCESS_API_STATUS = "POST_PROCESS_API_STATUS"
export const QA_PROCESS_API_STATUS = "QA_PROCESS_API_STATUS"

//Batch Monitor
export const GET_BATCH_DATA = "GET_BATCH_DATA";
export const LIST_BATCH_RECORD = "LIST_BATCH_RECORD";

//Audit Data
export const GET_AUDIT_LIST = "GET_AUDIT_LIST";
export const CLEAR_API_STATUS = "CLEAR_API_STATUS";
export const GET_AUDIT_DETAILS = "GET_AUDIT_DETAILS";
export const GET_USER_ACTIVITY = "GET_USER_ACTIVITY";
export const GET_USER_NAME = "GET_USER_NAME";
export const GET_FILTERED_USER_ACTIVITY = "GET_FILTERED_USER_ACTIVIT";
export const GET_DATA_POINT_CALCULATION = "GET_DATA_POINT_CALCULATION";
export const GET_DP_DETAILS = "GET_DP_DETAILS";
export const GET_MERGE_PROJECTS = "GET_MERGE_PROJECTS";
export const GET_MERGE_BATCHES = "GET_MERGE_BATCHES";
export const API_STATUS_MASTER = "API_STATUS_MASTER";
export const GET_MERGED_FILES = "GET_MERGED_FILES";
export const CLEAR_GET_MERGED_FILES = "CLEAR_GET_MERGED_FILES";

export const HANDLE_REFRESH_BOX_FOLDERS = "HANDLE_REFRESH_BOX_FOLDERS"