import {
  USER_LOGIN,
  LOGIN_SUCCSS,
  FORGOT_PASSWORD_STATUS,
  FORGOT_PASSWORD_LINK_STATUS,
  USER_LOGOUT,
  API_STATUS,
  LOGIN_FAIL,
  IS_LOGGED_IN_ALREADY,
  E_SIGN_UPLOAD_STATUS,
  GET_E_SIGNATURE,
} from "./types";
import authService from "../../services/authService";
import { errorMessage } from "../../constants/responseMessages";

let service = new authService();

export const userLogin = (body) => {
  return (dispatch) => {
    dispatch({ type: USER_LOGIN });
    dispatch({ type: IS_LOGGED_IN_ALREADY, payload: false });
    service
      .Login(body)
      .then((response) => {
        const users = response;
        dispatch({
          type: LOGIN_SUCCSS,
          payload: users,
        });
      })
      .catch((error) => {
        dispatch({
          type: LOGIN_FAIL,
          payload: error,
        });
      });
  };
};

export const userLogout = () => {
  return (dispatch) => {
    service
      .userLogout()
      .then((res) => {
        dispatch({ type: USER_LOGOUT, payload: "" });
      })
      .catch((error) => {
        dispatch({
          type: API_STATUS,
          payload: { message: errorMessage(error), loader: false },
        });
      });
    }
  }
  
  export function loginClear(){
    return (dispatch) => {
      dispatch({ type:LOGIN_SUCCSS, payload: '' })
    }
  }
  export const clearApiResponse = () => {
    return (dispatch) => {
      dispatch({
        type: API_STATUS,
        payload: {}
      });
    }
  }

  export const getUserSignature = (data) => {
    return (dispatch) => {
      dispatch({
        type: API_STATUS,
        payload: { message: "", loader: true, next: false },
      });
      service
        .getUserSignature(data)
        .then((response) => {
          dispatch({
            type: GET_E_SIGNATURE,
            payload: { data: response.data },
          });
          dispatch({
            type: API_STATUS,
            payload: {
              message: "", 
              loader: false,
              next: false,
            },
          });
        })
        .catch((error) => {
          dispatch({
            type: API_STATUS,
            payload: { message: errorMessage(error), loader: false, next: false },
          });
        });
    };
  };
  

  export const clearESighUploadStatue = () => {
    return (dispatch) => {
      dispatch(({
         type: E_SIGN_UPLOAD_STATUS,
         payload: {message: '', loader: false, next: false, }
       }))
      }
  }
  
  export const saveUserSignature = (data) => {
    return (dispatch) => {
      dispatch(({
        type: E_SIGN_UPLOAD_STATUS,
        payload: { message: '', loader: true, next: false }
      }))
      // dispatch(({
      //   type: API_STATUS,
      //   payload: { message: '', loader: true, next: false }
      // }))
      service.saveUserSignature(data).then(response => {
        // dispatch(({
        //   type: E_SIGN_UPLOAD_STATUS,
        //   payload: {message: response.data, }
        // }))
        // dispatch(({
        //   type: API_STATUS,
        //   payload: { message: response?.data?.message, loader: false, next: true }
        // }))
        dispatch(({
          type: E_SIGN_UPLOAD_STATUS,
          payload: { message: response?.data?.message, loader: false, next: true }
        }))
      })
      .catch((error) => {
        dispatch(({
          type: E_SIGN_UPLOAD_STATUS,
          payload: { message: errorMessage(error), loader: false, next: false  }
        }))
        // dispatch({
        //   type: API_STATUS,
        //   payload: { message: errorMessage(error), loader: false, next: false },
        // });
      });
  };
};

export const forgotPasswordPermission = (userName, newPassword) => {
  return (dispatch) => {
    dispatch({
      type: FORGOT_PASSWORD_STATUS,
      payload: { message: "", loader: true },
    });
    service
      .forgotPassword(userName, newPassword)
      .then((json) => {
        dispatch({
          type: FORGOT_PASSWORD_STATUS,
          payload: { message: json?.data?.message, loader: false, next: true },
        });
      })
      .catch((error) => {
        dispatch({
          type: FORGOT_PASSWORD_STATUS,
          payload: { message: error?.response?.data?.message, loader: false, next: false },
        });
      });
  };
};
export const forgotPasswordLink = (userName, passwordResetLink) => {
  return (dispatch) => {
    dispatch({
      type: FORGOT_PASSWORD_LINK_STATUS,
      payload: { message: "", loader: true },
    });
    service
      .forgotPasswordLink(userName, passwordResetLink)
      .then((json) => {
        dispatch({
          type: FORGOT_PASSWORD_LINK_STATUS,
          payload: { message: json?.data?.message, loader: false, next: true },
        });
      })
      .catch((error) => {
        dispatch({
          type: FORGOT_PASSWORD_LINK_STATUS,
          payload: {
            message: error.response.data.message,
            loader: false,
            next: false,
          },
        });
      });
  };
};

export const clearForgotPassLinkStatus = () => {
  return (dispatch) => {
    dispatch({
      type: FORGOT_PASSWORD_LINK_STATUS,
      payload: "",
    });
  };
};

export const clearForgoPasswordStatus = () => {
  return (dispatch) => {
    dispatch({
      type: FORGOT_PASSWORD_STATUS,
      payload: "",
    });
  };
};
export function loginInAlreadyClear() {
  return (dispatch) => {
    dispatch({ type: IS_LOGGED_IN_ALREADY, payload: false });
  };
}

export function loginFailClear() {
  return (dispatch) => {
    dispatch({
      type: LOGIN_FAIL,
      payload: "",
    });
  };
}
