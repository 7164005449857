import React, { useEffect, useState } from "react";
import Header from "../../../components/header/Header";
import LeftSideBar from "../../../components/LeftSideBar/LeftSideBar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Autocomplete,
  Box,
  FormControl,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import Tables from "../../../components/Table/Table";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import * as acts from "../../../redux/administrator/actions";
import AdministratorHeader from "../../../components/header/AdministratorHeader";
import "./OutPutFormat.scss";
import TablePagination from "@mui/material/TablePagination";
import CustomizedSnackbars from "../../../components/snackbars/CustomizedSnackbars";
import DateRangePicker from "../../../components/DateRangePicker";
import moment from "moment";
import ButtonCompo from "../../../components/Controls/buttons/Button";
import ClearIcon from "@mui/icons-material/Clear";
import { action } from "../../../constants/action";
import ProgressBar from "../../../components/ProgressBar";

const Index = () => {
  const listData = useSelector((state) => state?.admin?.outPutData);
  const postProcessStatus = useSelector(
    (state) => state?.admin.postProcessStatus
  );

  const qaProcessStatus = useSelector((state) => state?.admin.qaProcessStatus);

  const Dispatch = useDispatch();
  const history = useHistory();
  const [pageCount, setPageCount] = useState(listData?.total_count || 0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [outputData, setOutputData] = useState([]);
  const [page, setPage] = useState(0);
  const apiStatus = useSelector((state) => state?.admin?.uploadData);
  console.log(apiStatus);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [err, setErr] = useState(false);
  const [input, setInput] = useState("");
  const [fileType, setFileType] = useState("");
  const [status, setStatus] = useState("");
  const [sDate, setSDate] = useState("");
  const [eDate, setEDate] = useState("");
  const [checkedFiles, setCheckedFiles] = useState([]);
  const [type, setType] = useState("project");
  const [value, setValue] = useState("");
  const [fileValue, setFileValue] = useState("");
  const [ppStatus, setPPStatus] = useState("");
  const [qaCheckedFiles, setQACheckedFiles] = useState([]);

  useEffect(() => {
    const data = {
      page: page + 1,
      page_size: rowsPerPage,
      fileType,
      status,
      formattedStartDate: sDate,
      formattedEndDate: eDate,
      type,
      value,
      fileValue,
      ppStatus,
    };
    Dispatch(acts.getOutPutList(data));
  }, [rowsPerPage]);

  const fetchOutputListData = () => {
    const data = {
      page: page + 1,
      page_size: rowsPerPage,
      fileType,
      status,
      formattedStartDate: sDate,
      formattedEndDate: eDate,
      // ...(type !== null && {
      //   [type === "project" ? "projectName" : "batchName"]: value,
      // }),
      type,
      value,
      fileValue,
      ppStatus,
    };
    Dispatch(acts.getOutPutList(data));
  };

  useEffect(() => {
    fetchOutputListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (apiStatus && apiStatus !== undefined) {
      if (apiStatus?.message != null && apiStatus?.next === false) {
        if (apiStatus?.message === "Invalid Token") {
          setMessage(apiStatus?.message);
          setErr(true);
        }
      }
    }
  }, [apiStatus]);

  useEffect(() => {
    if (listData?.data) {
      setOutputData(listData?.data);
      setPageCount(listData?.total_count);
    }
  }, [listData?.data]);

  useEffect(() => {
    if (postProcessStatus && postProcessStatus !== undefined) {
      if (
        postProcessStatus?.loader === false &&
        postProcessStatus?.next === false &&
        postProcessStatus?.message != "" &&
        postProcessStatus?.message != null
      ) {
        setErr(true);
        setMessage(postProcessStatus?.message);
        Dispatch(acts?.clearPostProcessApiStatus());
      } else if (
        postProcessStatus?.loader === false &&
        postProcessStatus?.next === true
      ) {
        setSuccess(true);
        setMessage(postProcessStatus?.message);
        Dispatch(acts?.clearPostProcessApiStatus());
        setCheckedFiles([]);
      }
    }
  }, [postProcessStatus]);

  useEffect(() => {
    if (qaProcessStatus && qaProcessStatus !== undefined) {
      if (
        qaProcessStatus?.loader === false &&
        qaProcessStatus?.next === false &&
        qaProcessStatus?.message !== "" &&
        qaProcessStatus?.message !== null
      ) {
        setErr(true);
        setMessage(qaProcessStatus?.message);
        Dispatch(acts?.clearQAProcessApiStatus());
      } else if (
        qaProcessStatus?.loader === false &&
        qaProcessStatus?.next === true
      ) {
        setSuccess(true);
        setMessage(qaProcessStatus?.message);
        Dispatch(acts?.clearQAProcessApiStatus());
        setCheckedFiles([]);

        setTimeout(() => {
          // Open the file URL in a new tab or window
          window.open(qaProcessStatus.data, "_blank");
        }, 3000);
      }
    }
  }, [qaProcessStatus]);

  const handlePageChange = (event, page) => {
    setPage(page);
    Dispatch(
      acts.getOutPutList({
        page: page + 1,
        page_size: rowsPerPage,
        fileType,
        status,
        formattedStartDate: sDate,
        formattedEndDate: eDate,
        // ...(type !== null && {
        //   [type === "project" ? "projectName" : "batchName"]: value,
        // }),
        type,
        value,
        fileValue,
        ppStatus,
      })
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    setPageCount(listData?.total_count);
  };

  const newScreenNavigatin = () => {
    Dispatch(acts.getDpDetails());
    history.push("/data-point-details");
  };
  const filterByDate = (startDate, endDate) => {
    const formattedStartDate =
      startDate !== "" ? moment(startDate).format("YYYY-MM-DD") : "";
    const formattedEndDate =
      endDate !== "" ? moment(endDate).format("YYYY-MM-DD") : "";
    setSDate(formattedStartDate);
    setEDate(formattedEndDate);

    const data = {
      page: page + 1,
      page_size: rowsPerPage,
      fileType,
      status,
      formattedStartDate: formattedStartDate,
      formattedEndDate: formattedEndDate,
      type,
      value,
      fileValue,
      ppStatus,
    };
    Dispatch(acts.getOutPutList(data));
  };
  const clear = () => {
    // setFileType("")
    // setStatus("")
    // Dispatch(
    //   acts.getDeletedData({
    //     formattedStartDate: "",
    //     formattedEndDate: "",
    //   })
    // );
  };
  const handleFileType = (event) => {
    setFileType(event.target.value);
  };
  const handleStatus = (event) => {
    setStatus(event.target.value);
  };

  const handlePPStatus = (event) => {
    setPPStatus(event.target.value);
  };

  const onChangeSelectFiles = (id) => {
    setCheckedFiles((prev) => {
      if (prev.includes(id)) {
        // If the ID is already in the array, remove it
        return prev.filter((fileId) => fileId !== id);
      } else {
        // If the ID is not in the array, add it
        return [...prev, id];
      }
    });
  };

  const onChangeSelectForQA = (id) => {
    setQACheckedFiles((prev) => {
      if (prev.includes(id)) {
        // If the ID is already in the array, remove it
        return prev.filter((fileId) => fileId !== id);
      } else {
        // If the ID is not in the array, add it
        return [...prev, id];
      }
    });
  };

  const submitFiles = () => {
    Dispatch(acts.submitFinalOpFilesForPostProcess(checkedFiles));
  };

  const submitFilesForQA = () => {
    Dispatch(acts.submitFilesForQA(qaCheckedFiles));
  };

  const clearFileType = () => {
    setFileType("");
  };

  const clearStatus = () => {
    setStatus("");
  };

  const clearPPStatus = () => {
    setPPStatus("");
  };

  const clearValue = () => {
    setValue("");
  };

  const clearFileValue = () => {
    setFileValue("");
  };

  const onChangeFileValue = (value) => {
    setFileValue(value);
  };

  const onChangeValue = (value) => {
    setValue(value);
  };

  const handleChange = (event) => {
    setType(event.target.value);
    setValue("");
  };

  return (
    <>
      <Header />
      <Box className="manage-output-files">
        <Grid container>
          <Grid item md={3} sm={3}>
            <LeftSideBar page="sidebar" />
          </Grid>
          <Grid className="manage-output-conatiner" item md={9} sm={9}>
            <AdministratorHeader
              text="Manage Output Files"
              outPutButton={"Data Point Details"}
              Click={newScreenNavigatin}
            />
            <Grid
              container
              className="input-wrap-container"
              spacing={1}
              alignItems="center"
              justifyContent="flex-start"
            >
              <Grid item xs={12} sm={6.5}>
                <div className="search-container">
                  <FormControl size="small">
                    <Select
                      size="small"
                      className="dropdown-input-select"
                      labelId="demo-simple-select-autowidth-label"
                      id="demo-simple-select-autowidth"
                      value={type}
                      onChange={handleChange}
                      autoWidth
                    >
                      <MenuItem value="project" selected={true}>
                        {action.PROJECT}
                      </MenuItem>
                      <MenuItem value="batch">{action.BATCH}</MenuItem>
                    </Select>
                  </FormControl>

                  <div className="search">
                    <FormControl fullWidth size="small">
                      <InputBase
                        size="small"
                        id="searchInput"
                        className="input-style"
                        placeholder={"Search Project"}
                        inputProps={{ "aria-label": "search" }}
                        onChange={(event) => onChangeValue(event.target.value)}
                        value={value}
                      />
                      {value && (
                        <IconButton
                          onClick={() => clearValue()}
                          size="small"
                          style={{
                            position: "absolute",
                            top: "50%",
                            right: 1,
                            transform: "translateY(-50%)",
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      )}
                    </FormControl>
                  </div>

                  <div className="search file-name-search">
                    <FormControl fullWidth size="small">
                      <InputBase
                        size="small"
                        id="searchInput"
                        className="input-style"
                        placeholder={"Search File"}
                        inputProps={{ "aria-label": "search" }}
                        onChange={(event) =>
                          onChangeFileValue(event.target.value)
                        }
                        value={fileValue}
                      />
                      {fileValue && (
                        <IconButton
                          onClick={() => clearFileValue()}
                          size="small"
                          style={{
                            position: "absolute",
                            top: "50%",
                            right: 1,
                            transform: "translateY(-50%)",
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      )}
                    </FormControl>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} sm={1.5}>
                <FormControl fullWidth size="small">
                  <InputLabel
                    className="multiple-controlsLabel"
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name-label"
                  >
                    File Type
                  </InputLabel>
                  <Select
                    className="b-0"
                    size="small"
                    label="File Type"
                    value={fileType}
                    onChange={handleFileType}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="xlsx">xlsx</MenuItem>
                    <MenuItem value="csv">csv</MenuItem>
                    <MenuItem value="parquet">parquet</MenuItem>
                  </Select>
                  {fileType && (
                    <IconButton
                      onClick={() => clearFileType()}
                      size="small"
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: 25,
                        transform: "translateY(-50%)",
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={2}>
                <FormControl fullWidth size="small">
                  <InputLabel
                    className="multiple-controlsLabel"
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name-label"
                  >
                    Status
                  </InputLabel>
                  <Select
                    className="b-0"
                    label="Status"
                    size="small"
                    value={status}
                    onChange={handleStatus}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="approved">Approved</MenuItem>
                    <MenuItem value="rejected">Rejected</MenuItem>
                    <MenuItem value="unapproved">Unapproved</MenuItem>
                  </Select>
                  {status && (
                    <IconButton
                      onClick={() => clearStatus()}
                      size="small"
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: 25,
                        transform: "translateY(-50%)",
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={2}>
                <FormControl fullWidth size="small">
                  <InputLabel
                    className="multiple-controlsLabel"
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name-label"
                  >
                    Post Processing Status
                  </InputLabel>
                  <Select
                    className="b-0"
                    label="Status"
                    size="small"
                    value={ppStatus}
                    onChange={handlePPStatus}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="true">Processed</MenuItem>
                    <MenuItem value="false">Not Processed</MenuItem>
                  </Select>
                  {ppStatus && (
                    <IconButton
                      onClick={() => clearPPStatus()}
                      size="small"
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: 25,
                        transform: "translateY(-50%)",
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={5} sx={{ zIndex: "999" }}>
                <DateRangePicker filterByDate={filterByDate} clear={clear} />
              </Grid>

              <Grid item style={{ marginLeft: "auto" }}>
                <ButtonCompo
                  text="Submit For Post Process"
                  // size="medium"
                  className="btn-search"
                  onClick={() => submitFiles()}
                  variant="contained"
                  disabled={checkedFiles.length <= 0}
                />
                <ButtonCompo
                  text="Download QA Report"
                  // size="medium"
                  className="btn-search"
                  onClick={() => submitFilesForQA()}
                  variant="contained"
                  disabled={qaCheckedFiles.length <= 0}
                />
              </Grid>
            </Grid>
            <Box className="manage-data-list">
              <Tables
                data={outputData}
                onChangeSelectFiles={onChangeSelectFiles}
                checkedFiles={checkedFiles}
                onChangeSelectForQA={onChangeSelectForQA}
                qaCheckedFiles={qaCheckedFiles}
              />
            </Box>
            {err && (
              <CustomizedSnackbars
                severity="error"
                state={err}
                text={message}
                handleClose={() => setErr(false)}
              />
            )}
            <Grid className="pagination-wrap">
              <TablePagination
                rowsPerPageOptions={[15, 25, 50, 100]}
                component="div"
                count={pageCount}
                page={parseInt(page)}
                onPageChange={handlePageChange}
                rowsPerPage={parseInt(rowsPerPage)}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
          </Grid>
        </Grid>

        {qaProcessStatus?.loader ? <ProgressBar /> : ""}

        {success && (
          <CustomizedSnackbars
            severity="success"
            state={success}
            text={message}
            handleClose={() => setSuccess(false)}
          />
        )}

        {err && (
          <CustomizedSnackbars
            severity="error"
            state={err}
            text={message}
            handleClose={() => setErr(false)}
          />
        )}
      </Box>
    </>
  );
};

export default Index;
