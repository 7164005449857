import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  FormControl,
  Input,
  Button,
  InputAdornment,
  IconButton,
  Snackbar,
  Alert,
  AlertTitle,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import AddCircleTwoToneIcon from "@mui/icons-material/AddCircleTwoTone";
import { Prompt } from "react-router";
import Table from "./Table";
import moment from "moment";
import { mapData } from "../../../redux/pre_processor/actions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import "./freeFrom.scss";
import {
  clearQaReportResult,
  dataEntry,
  generateQAReport,
  getProDataForReset,
  rejectData,
  setOCRValue,
} from "../../../redux/processor/actions";
import {
  rejectValidatorData,
  validateData,
} from "../../../redux/validator/actions";
import Authentication from "../../Modal/PopupModal";
import ModalSign from "../../Modal/signature/ModalSign";
import ProgressBar from "../../ProgressBar";
import CustomizedSnackbars from "../../snackbars/CustomizedSnackbars";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.2rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }

      input {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
      }
    }
  }
`;

const headerAlphabetString = (num) => {
  let s = "";
  let t = null;

  while (num > 0) {
    t = (num - 1) % 26;
    s = String.fromCharCode(65 + t) + s;
    num = ((num - t) / 26) | 0;
  }
  return s || "";
};

const CNCTableWrapper = React.forwardRef((props, ref) => {
  const {
    selectedFile,
    selectedArea,
    role,
    parametersData: parametersDataFromState,
    columnsCount: countOfColumns,
    reProcess,
    reValidate,
    areaIndex,
    isDataRejectedByProcessor,
    isDataRejectedByValidator,
    showContextMenu,
    isDisableOnChangeInput,
    isDisableOnBlur,
    isFullView,
    resetPdfHeighlights,
    entireBatchRejectedByPro,
    entireBatchRejectedByVali,
    reProcessEntireBatch,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  let initialRowsData;

  if (parametersDataFromState.length > 0) {
    initialRowsData = parametersDataFromState;
  } else {
    initialRowsData = [{}, {}, {}, {}, {}];
  }
  const bindOcrValuesAndCoords = useSelector(
    (state) => state?.pro?.bindOcrValuesAndCoords
  );
  const selectedFilesDataForProUpdate = useSelector(
    (state) => state?.pro?.selectedFilesDataForProUpdate
  );
  const { loading, data, error, next } = useSelector(
    (state) => state?.pro?.qaReportResult || {}
  );

  const [skipPageReset, setSkipPageReset] = useState(false);
  const [rowsData, setRowsData] = useState(initialRowsData);
  const [rejectedCount, setRejectedCount] = useState(0);
  const [columns, setColumns] = useState([]);
  const [columnsCount, setColumnsCount] = useState(
    countOfColumns ? countOfColumns : 5
  );
  const anyOperationPerformedOnEntirePage = React.useRef(false);
  const [cellToUpdate, setCellToUpdate] = useState({
    rowIndex: null,
    columnId: null,
  });
  const [isAlert, setAlert] = useState(false);
  const [openAuth, setOpenAuth] = useState(false);
  const [openSignPad, setOpenSignPad] = useState(false);
  const rowInputRef = React.useRef();
  const colInputRef = React.useRef();
  const [openConfirmAlert, setOpenConfirmAlert] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const handleCloseAuth = () => {
    setOpenAuth(false);
  };

  const handleCloseSignPad = () => {
    setOpenSignPad(false);
  };

  const handleUserPermission = () => {
    dispatch(
      validateData({
        parametersData: rowsData,
        batch_id: localStorage.batchId,
        projectId: localStorage.projectId,
        next: true,
        extractionType: "free-form",
        partially_validated: false,
        columnsCount: columnsCount,
      })
    );
  };

  React.useImperativeHandle(ref, () => ({
    mapData(action, remark, batchRejection, signUploaded) {
      anyOperationPerformedOnEntirePage.current = false;
      if (action !== "save") {
        if (rejectedCount >= 1) {
          action = "reject";
        }
      }
      const next = action === "saveAndNext" ? true : false;
      const extractionType = "free-form";

      if (role === "preProcessor") {
        if (action === "saveAndNext") {
          dispatch(
            mapData({
              parametersData: rowsData,
              projectId: localStorage.projectId,
              next: next,
              extractionType: extractionType,
              isUpdateParameters: false,
              ...(isDataRejectedByProcessor && {
                isDataRejectedByProcessor: true,
              }),
              ...(isDataRejectedByProcessor && {
                reProcess: true,
              }),
              ...(isDataRejectedByValidator && {
                isDataRejectedByValidator: true,
              }),
              entireBatchRejectedByPro: false,
              ...(entireBatchRejectedByPro && {
                reProcessEntireBatch: true,
              }),
              columnsCount: columnsCount,
            })
          );
        }
        if (action === "save") {
          dispatch(
            mapData({
              parametersData: rowsData,
              projectId: localStorage.projectId,
              next: next,
              extractionType: extractionType,
              isUpdateParameters: false,
              ...(isDataRejectedByProcessor && {
                isDataRejectedByProcessor: true,
              }),
              ...(isDataRejectedByValidator && {
                isDataRejectedByValidator: true,
              }),
              ...(entireBatchRejectedByPro && {
                entireBatchRejectedByPro: true,
              }),
              columnsCount: columnsCount,
            })
          );
        }
      }

      if (role === "processor") {
        if (action === "reject") {
          dispatch(
            rejectData({
              parametersData: rowsData,
              batch_id: localStorage.batchId,
              next: next,
              extractionType: extractionType,
              ...(isDataRejectedByValidator && {
                isDataRejectedByValidator: true,
              }),
              rejection_remark: remark,
              isDataRejectedByProcessor: true,
              projectId: localStorage.projectId,
              entireBatchRejectedByPro: batchRejection === true ? true : false,
              ...(entireBatchRejectedByVali && {
                entireBatchRejectedByVali: true,
              }),
              columnsCount: columnsCount,
            })
          );
        } else if (action === "save") {
          dispatch(
            dataEntry({
              parametersData: rowsData,
              batch_id: localStorage.batchId,
              next: next,
              extractionType: extractionType,
              // ...(isDataRejectedByValidator && {
              //   reValidate: true,
              // }),
              ...(isDataRejectedByValidator && {
                isDataRejectedByValidator: true,
              }),
              partially_validated: true,
              ...(entireBatchRejectedByVali && {
                entireBatchRejectedByVali: true,
              }),
              //  newly added
              ...(isDataRejectedByProcessor && {
                isDataRejectedByProcessor: true,
              }),
              ...(isDataRejectedByProcessor && {
                reProcess: true,
              }),
              // ...(entireBatchRejectedByPro && {
              //   entireBatchRejectedByPro: true,
              // }),
              ...(reProcessEntireBatch && {
                reProcessEntireBatch: true,
              }),
              columnsCount: columnsCount,
            })
          );
        } else if (action === "saveAndNext") {
          dispatch(
            dataEntry({
              parametersData: rowsData,
              batch_id: localStorage.batchId,
              next: next,
              extractionType: extractionType,
              ...(isDataRejectedByValidator && {
                reValidate: true,
              }),
              ...(isDataRejectedByValidator && {
                isDataRejectedByValidator: true,
              }),
              partially_validated: false,
              entireBatchRejectedByVali: false,
              ...(entireBatchRejectedByVali && {
                reValidateEntireBatch: true,
              }),
              columnsCount: columnsCount,
            })
          );
        }
      }

      if (role === "validator") {
        if (action === "reject") {
          dispatch(
            rejectValidatorData({
              parametersData: rowsData,
              batch_id: localStorage.batchId,
              next: next,
              extractionType: extractionType,
              rejection_remark: remark,
              isDataRejectedByValidator: true,
              projectId: localStorage.projectId,
              entireBatchRejectedByVali: batchRejection === true ? true : false,
              columnsCount: columnsCount,
            })
          );
        } else if (action === "saveAndNext") {
          let allValidatedData;
          if (rowsData) {
            allValidatedData = rowsData.every(
              (item) => item?.rowValidation?.isValidated
            );
          } else {
            allValidatedData = false;
          }

          if (allValidatedData) {
            if (signUploaded === false) {
              setOpenSignPad(true);
            } else if (signUploaded === true) {
              setOpenAuth(true);
            }
            // dispatch(
            //   validateData({
            //     parametersData: rowsData,
            //     batch_id: localStorage.batchId,
            //     projectId: localStorage.projectId,
            //     next: next,
            //     extractionType: extractionType,
            //     partially_validated: false,
            //     columnsCount: columnsCount,
            //   })
            // );
          } else {
            setAlert(true);
          }
        } else if (action === "save") {
          dispatch(
            validateData({
              parametersData: rowsData,
              batch_id: localStorage.batchId,
              projectId: localStorage.projectId,
              next: next,
              extractionType: extractionType,
              partially_validated: true,
              columnsCount: columnsCount,
            })
          );
        }
      }
    },
  }));

  useEffect(() => {
    window.addEventListener("beforeunload", beforeunloadFunc);

    return () => {
      // Anything in here is fired on component unmount.
      window.removeEventListener("beforeunload", beforeunloadFunc);
    };
  }, []);

  const addInitialColumns = () => {
    const emptyColumns = [];
    for (let i = 0; i < 5; i++) {
      emptyColumns.push({
        accessor: `col${i + 1}`,
        Header: headerAlphabetString(i + 1),
        width: 100,
      });
    }
    setColumns([...emptyColumns]);
  };

  useEffect(() => {
    if (countOfColumns && countOfColumns > 0) {
      for (let i = 0; i < countOfColumns; i++) {
        columns.push({
          accessor: `col${i + 1}`,
          Header: headerAlphabetString(i + 1),
          width: 100,
        });
      }
      setColumns([...columns]);
    } else {
      // initially  adding 5 empty columns
      addInitialColumns();
    }
  }, [countOfColumns]);

  const beforeunloadFunc = (e) => {
    if (anyOperationPerformedOnEntirePage.current) {
      e.preventDefault();
      e.returnValue = true;
    }
  };

  useEffect(() => {
    if (bindOcrValuesAndCoords?.coords && bindOcrValuesAndCoords?.value) {
      updateCell(bindOcrValuesAndCoords);
      dispatch(setOCRValue());

      // updatedCellCallBack();
      if (!anyOperationPerformedOnEntirePage.current) {
        anyOperationPerformedOnEntirePage.current = true;
      }
      setSkipPageReset(true);
    }
  }, [bindOcrValuesAndCoords]);

  const updateCell = (bindOcrValuesAndCoords) => {
    if (cellToUpdate?.rowIndex >= 0 && cellToUpdate?.columnId) {
      var bindOcrValues = bindOcrValuesAndCoords?.value;

      // var transformedHashToNumber = bindOcrValuesAndCoords?.value
      // ?.replace("#", "Number");

      let updatedValue;

      if (
        rowsData[cellToUpdate?.rowIndex][cellToUpdate?.columnId] &&
        rowsData[cellToUpdate?.rowIndex][cellToUpdate?.columnId].value !==
          bindOcrValuesAndCoords?.value
      ) {
        updatedValue = `${
          rowsData[cellToUpdate?.rowIndex][cellToUpdate?.columnId].value
        } ${bindOcrValues}`;
      } else {
        updatedValue = `${bindOcrValues}`;
      }

      let transformedValue = updatedValue
        ?.replace("#", "Number") // Replace # with "Number"
        .trim() // Remove leading and trailing spaces
        .replace(/\s+_/g, "_") // Remove spaces before underscores
        .replace(/_\s+/g, "_") // Remove spaces after underscores
        .replace(/\s{2,}/g, " ") // Replace double spaces with single space
        .replace(/[\r\n]+/g, ""); // Remove newlines and carriage returns

      rowsData[cellToUpdate?.rowIndex][cellToUpdate?.columnId] = {
        ...rowsData[cellToUpdate?.rowIndex][cellToUpdate?.columnId],
        value: transformedValue,

        coordinates:
          rowsData[cellToUpdate?.rowIndex][cellToUpdate?.columnId]
            ?.coordinates &&
          rowsData[cellToUpdate?.rowIndex][cellToUpdate?.columnId]?.coordinates
            ?.length >= 0
            ? [
                ...rowsData[cellToUpdate?.rowIndex][cellToUpdate?.columnId]
                  ?.coordinates,
                bindOcrValuesAndCoords?.coords,
              ]
            : [bindOcrValuesAndCoords?.coords],

        coordinatesValues:
          rowsData[cellToUpdate?.rowIndex][cellToUpdate?.columnId]
            ?.coordinatesValues &&
          rowsData[cellToUpdate?.rowIndex][cellToUpdate?.columnId]
            ?.coordinatesValues?.length >= 0
            ? [
                ...rowsData[cellToUpdate?.rowIndex][cellToUpdate?.columnId]
                  ?.coordinatesValues,
                bindOcrValuesAndCoords?.value,
              ]
            : [bindOcrValuesAndCoords?.value],

        processor: localStorage.userRole,
        processedDate: moment(new Date()).format("DD-MMM-YYYY"),
      };

      setRowsData([...rowsData]);
    }
  };

  const setCellToUpdateToState = ({ rowIndex, columnId }) => {
    setCellToUpdate({
      // used for pre and pro role
      rowIndex: rowIndex,
      columnId: columnId,
    });
  };

  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = ({ rowIndex, columnId, value, type }) => {
    // this func called from 2 location based on role wise and from one location which is pre pro we are not passing value
    setSkipPageReset(true);
    if (role === "preProcessor") {
      // in pre-pro we are only saving mappings not value
      if (
        rowsData[rowIndex][columnId] === undefined ||
        rowsData[rowIndex][columnId]
      ) {
        if (isDataRejectedByProcessor) {
          if (
            selectedFile?.fileNo !== rowsData[rowIndex][columnId]?.fileNo ||
            selectedFile?.pageNo !== rowsData[rowIndex][columnId]?.pageNo ||
            selectedFile?.tagName !== rowsData[rowIndex][columnId]?.tagName
          ) {
            rowsData[rowIndex][columnId] = {
              ...rowsData[rowIndex][columnId],
              value: "",
              ...selectedFile,
              coordinates: [],
              preProcessor: localStorage?.userName,
              preProcessedDate: moment(new Date()).format("MM-DD-YYYY"),
              indexesOfCoordinates: [],
              coordinatesValues: [],
            };
          }
        } else {
          rowsData[rowIndex][columnId] = {
            value: "",
            ...selectedFile,
            coordinates: [],
            coordinatesValues: [],
            preProcessor: localStorage?.userName,
            preProcessedDate: moment(new Date()).format("MM-DD-YYYY"),
          };
        }
        setRowsData([...rowsData]);
        if (!anyOperationPerformedOnEntirePage.current) {
          anyOperationPerformedOnEntirePage.current = true;
        }
      }
    }

    // We also turn on the flag to not reset the page

    if (role === "processor") {
      if (type === "Click") {
        // used to set row and col indexes to state for the use in click and capture time in pro (data update)
        setCellToUpdate({
          rowIndex: rowIndex,
          columnId: columnId,
        });
      }
      // in pro we are not storing mappjing instead storing value

      let transformedValue = value
        // ?.replace("#", "Number") // Replace # with "Number"
        .trim() // Remove leading and trailing spaces
        .replace(/\s+_/g, "_") // Remove spaces before underscores
        .replace(/_\s+/g, "_") // Remove spaces after underscores
        .replace(/\s{2,}/g, " ") // Replace double spaces with single space
        .replace(/[\r\n]+/g, ""); // Remove newlines and carriage returns

      if (
        rowsData[rowIndex][columnId] === undefined ||
        (rowsData[rowIndex][columnId] &&
          rowsData[rowIndex][columnId].value !== value)
      ) {
        rowsData[rowIndex][columnId] = {
          ...rowsData[rowIndex][columnId],
          value: transformedValue,
          //   ...pdfAndPageNoState,
          processor: localStorage?.userName,
          processedDate: moment(new Date()).format("MM-DD-YYYY"),
        };
        setRowsData([...rowsData]);
        if (!anyOperationPerformedOnEntirePage.current) {
          anyOperationPerformedOnEntirePage.current = true;
        }
      }
    }
  };

  const rejectCell = ({ rejectionRemark, rowIndex, columnId }) => {
    if (role === "processor") {
      rowsData[rowIndex][columnId] = {
        ...rowsData[rowIndex][columnId],
        processorCellRejection: {
          isRejected: true,
          remark: rejectionRemark,
          date: moment(new Date()).format("DD-MMM-YYYY"),
          rejectedBy: localStorage?.userName,
        },
      };
    }
    if (role === "validator") {
      rowsData[rowIndex][columnId] = {
        ...rowsData[rowIndex][columnId],
        validatorCellRejection: {
          isRejected: true,
          remark: rejectionRemark,
          date: moment(new Date()).format("DD-MMM-YYYY"),
          rejectedBy: localStorage?.userName,
        },
      };
    }
    setRejectedCount((prevState) => (prevState >= 0 ? prevState + 1 : 0)); // increament counter used this to toggle api(save / reject) based on rejection in row or cell happened or not
    setRowsData([...rowsData]);
    if (!anyOperationPerformedOnEntirePage.current) {
      anyOperationPerformedOnEntirePage.current = true;
    }
    setSkipPageReset(true);
  };

  const markAsResolveRejection = (parameterIndex, index) => {
    // In Pro ROle - mark rejection did by validator
    if (role === "processor") {
      rowsData[parameterIndex].validatorRowRejection = {
        isResolved: true,
        date: moment(new Date()).format("DD-MMM-YYYY"),
        resolvedBy: localStorage?.userName,
      };
      setRowsData([...rowsData]);
      // setSkipPageReset(true);
    }
    if (role === "preProcessor") {
      rowsData[parameterIndex].processorRowRejection = {
        isResolved: true,
        resolvedDate: moment(new Date()).format("DD-MMM-YYYY"),
        resolvedBy: localStorage?.userName,
      };
      setRowsData([...rowsData]);
    }
    if (!anyOperationPerformedOnEntirePage.current) {
      anyOperationPerformedOnEntirePage.current = true;
    }
    setSkipPageReset(true);
  };

  const deleteRejection = ({ rowIndex, columnId }) => {
    if (role === "processor") {
      delete rowsData[rowIndex][columnId]["processorCellRejection"];
    }
    if (role === "validator") {
      delete rowsData[rowIndex][columnId]["validatorCellRejection"];
    }
    setRejectedCount((prevState) => (prevState > 0 ? prevState - 1 : 0)); // decrement   counter used this to toggle api(save / reject) based on rejection in row or cell happened or not
    setRowsData([...rowsData]);
    if (!anyOperationPerformedOnEntirePage.current) {
      anyOperationPerformedOnEntirePage.current = true;
    }
    setSkipPageReset(true);
  };

  const handleRowRejection = (index, remark) => {
    if (role === "processor") {
      rowsData[index] = {
        ...rowsData[index],
        processorRowRejection: {
          isRejected: true,
          remark: remark,
          date: moment(new Date()).format("DD-MMM-YYYY"),
          rejectedBy: localStorage?.userName,
        },
      };
    }
    if (role === "validator") {
      rowsData[index] = {
        ...rowsData[index],
        validatorRowRejection: {
          isRejected: true,
          remark: remark,
          date: moment(new Date()).format("DD-MMM-YYYY"),
          rejectedBy: localStorage?.userName,
        },
      };
    }
    setRejectedCount((prevState) => (prevState >= 0 ? prevState + 1 : 0)); // increament counter used this to toggle api(save / reject) based on rejection in row or cell happened or not
    setRowsData([...rowsData]);
    if (!anyOperationPerformedOnEntirePage.current) {
      anyOperationPerformedOnEntirePage.current = true;
    }
    setSkipPageReset(true);
  };

  const deleteRowRejection = (index) => {
    if (role === "processor") {
      delete rowsData[index]["processorRowRejection"];
    }
    if (role === "validator") {
      delete rowsData[index]["validatorRowRejection"];
    }
    setRejectedCount((prevState) => (prevState > 0 ? prevState - 1 : 0)); // decrement counter used this to toggle api(save / reject) based on rejection in row or cell happened or not
    setRowsData([...rowsData]);
    if (!anyOperationPerformedOnEntirePage.current) {
      anyOperationPerformedOnEntirePage.current = true;
    }
    setSkipPageReset(true);
  };

  const markAsResolveByProcessor = ({ rowIndex, columnId }) => {
    if (role === "preProcessor") {
      rowsData[rowIndex][columnId] = {
        ...rowsData[rowIndex][columnId],
        // isMarkedAsResolvedByProcessor: true,
        processorCellRejection: {
          isResolved: true,
          resolvedDate: moment(new Date()).format("DD-MMM-YYYY"),
          resolvedBy: localStorage?.userName,
        },
      };
    }
    if (role === "processor") {
      rowsData[rowIndex][columnId] = {
        ...rowsData[rowIndex][columnId],
        // isMarkedAsResolvedByProcessor: true,
        validatorCellRejection: {
          isResolved: true,
          resolvedDate: moment(new Date()).format("DD-MMM-YYYY"),
          resolvedBy: localStorage?.userName,
        },
      };
    }
    setRowsData([...rowsData]);
    if (!anyOperationPerformedOnEntirePage.current) {
      anyOperationPerformedOnEntirePage.current = true;
    }
    setSkipPageReset(true);
  };

  const onClickValidate = (action, parameterIndex) => {
    setSkipPageReset(true);
    if (action === "validate") {
      rowsData[parameterIndex]["rowValidation"] = {
        ...rowsData[parameterIndex]["rowValidation"],
        isValidated: true,
        validator: localStorage?.userName,
        date: moment(new Date()).format("DD-MMM-YYYY HH:mm:ss"),
      };
    } else {
      rowsData[parameterIndex]["rowValidation"] = {};
    }
    setRowsData([...rowsData]);
    if (!anyOperationPerformedOnEntirePage.current) {
      anyOperationPerformedOnEntirePage.current = true;
    }
  };

  useEffect(() => {
    // This logic is used for pre pro role to add coordinates in cell wise when mapping data

    if (selectedArea) {
      setSkipPageReset(true);

      const coordinatesToAdd = selectedArea?.coords;
      const rowIndex = cellToUpdate?.rowIndex;
      const columnId = cellToUpdate?.columnId;
      if (
        selectedArea.coords &&
        rowIndex !== null &&
        rowIndex >= 0 &&
        columnId &&
        rowsData
      ) {
        if (isDataRejectedByProcessor) {
          rowsData[rowIndex][columnId] = {
            ...rowsData[rowIndex][columnId],
            coordinates: [
              ...rowsData[rowIndex][columnId]?.coordinates,
              coordinatesToAdd,
            ],
            indexesOfCoordinates: [
              ...rowsData[rowIndex][columnId]?.indexesOfCoordinates,
              areaIndex,
            ],
          };
        } else {
          rowsData[rowIndex][columnId]["coordinates"] = [
            ...rowsData[rowIndex][columnId]?.coordinates,
            coordinatesToAdd,
          ];

          rowsData[rowIndex][columnId]["indexesOfCoordinates"] = [
            ...(rowsData[rowIndex][columnId]["indexesOfCoordinates"]
              ? [
                  ...rowsData[rowIndex][columnId]["indexesOfCoordinates"],
                  areaIndex,
                ]
              : [areaIndex]),
          ];
        }

        // from be getting defficult to change payload so creating seperate array for values to show in cell menu
        if (
          rowsData[rowIndex][columnId]?.tagName &&
          rowsData[rowIndex][columnId]?.tagName !== "" &&
          rowsData[rowIndex][columnId]?.pageNo >= 0
        ) {
          rowsData[rowIndex][columnId].coordinatesValues = rowsData[rowIndex][
            columnId
          ].coordinatesValues
            ? [
                ...rowsData[rowIndex][columnId].coordinatesValues,
                selectedArea?.name,
              ]
            : [selectedArea?.name];
        }

        setRowsData([...rowsData]);
        if (!anyOperationPerformedOnEntirePage.current) {
          anyOperationPerformedOnEntirePage.current = true;
        }
      }
    }
  }, [selectedArea]);

  useEffect(() => {
    if (data?.data) {
      // Show success message
      setSnackbar({
        open: true,
        message: "QA Report downloaded successfully!",
        severity: "success",
      });

      // Clear the report data after download
      dispatch(clearQaReportResult());

      setTimeout(() => {
        const link = document.createElement("a");
        link.href = data.data; // The URL to the file
        // link.download = "QA_Report.pdf"; // Specify a filename (optional)
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        // window.open(data.data, "_blank");
      }, [3000]);
    } else if (error) {
      setSnackbar({ open: true, message: error, severity: "error" });
      dispatch(clearQaReportResult());
    }
  }, [data, error, dispatch]);

  const handleCloseSnackbar = () => {
    setSnackbar({ open: false, message: "", severity: "" });
  };

  // Let's add a data resetter/randomizer to help
  // illustrate that flow...
  const resetData = () => {
    setRowsData([{}, {}, {}, {}, {}]);
    addInitialColumns();
    setColumnsCount(0);
    setCellToUpdate({
      rowIndex: null,
      columnId: null,
    });
    resetPdfHeighlights();
    if (!anyOperationPerformedOnEntirePage.current) {
      anyOperationPerformedOnEntirePage.current = true;
    }
  };

  const resetDataApiCall = () => {
    dispatch(getProDataForReset());
  };

  const clickAddRowChange = () => {
    const newRowsNo = !isNaN(rowInputRef.current.value)
      ? Number(rowInputRef.current.value)
      : 0;

    if (newRowsNo > 0) {
      for (let i = 0; i < newRowsNo; i++) {
        rowsData.push({});
      }
      setRowsData([...rowsData]);
      rowInputRef.current.value = "";
    }
    if (!anyOperationPerformedOnEntirePage.current) {
      anyOperationPerformedOnEntirePage.current = true;
    }
    setSkipPageReset(true);
  };

  const clickAddColumnChange = (callFrom) => {
    let noOfColToAdd;
    if (callFrom && callFrom === "moreRowCall") {
      noOfColToAdd = 1; // add one new column in state for add col in betn functionality and after adding column shifting columns values accordingly
    } else {
      noOfColToAdd = !isNaN(colInputRef.current.value)
        ? Number(colInputRef.current.value)
        : 0;
    }

    if (noOfColToAdd > 0) {
      let columnsFromState = columns;

      for (let i = 0; i < noOfColToAdd; i++) {
        let length = columnsFromState.length;

        columnsFromState.push({
          accessor: `col${length + 1}`,
          Header: headerAlphabetString(length + 1),
          width: 100,
        });
      }

      setColumns([...columnsFromState]);
      setColumnsCount((prevCount) => prevCount + noOfColToAdd);
      colInputRef.current.value = "";
    }
    if (!anyOperationPerformedOnEntirePage.current) {
      anyOperationPerformedOnEntirePage.current = true;
    }
    setSkipPageReset(true);
  };

  const addColInBetween = (id, pos) => {
    if (pos === "before") {
      clickAddColumnChange("moreRowCall"); // to add new col
      const cellToBeAddDigitNo = parseInt(id.match(/\d+/)[0]);
      const tempRowsData = rowsData;
      const newRowsData = [];

      tempRowsData.map((item, index) => {
        const orgData = { ...item };
        [...Array(columns?.length).keys()].map((index) => {
          if (index + 1 >= cellToBeAddDigitNo) {
            orgData[`col${index + 2}`] = item[`col${index + 1}`];
          }
        });
        delete orgData[id];

        newRowsData.push(orgData);
      });
      setRowsData([...newRowsData]);
    } else if (pos === "after") {
      let cellToBeAdd = `col${parseInt(id.match(/\d+/)[0]) + 1}`;
      clickAddColumnChange("moreRowCall"); // to add new col
      const cellToBeAddDigitNo = parseInt(cellToBeAdd.match(/\d+/)[0]);
      const tempRowsData = rowsData;
      const newRowsData = [];

      tempRowsData.map((item, index) => {
        const orgData = { ...item };
        [...Array(columns?.length).keys()].map((index) => {
          if (index + 1 >= cellToBeAddDigitNo) {
            orgData[`col${index + 2}`] = item[`col${index + 1}`];
          }
        });
        delete orgData[cellToBeAdd];

        newRowsData.push(orgData);
      });
      setRowsData([...newRowsData]);
    }
    if (!anyOperationPerformedOnEntirePage.current) {
      anyOperationPerformedOnEntirePage.current = true;
    }
    setSkipPageReset(true);
  };

  const deleteColumn = (colIdTODelete) => {
    const tempRowsData = rowsData;
    const newRowsData = [];
    let columnsFromState = columns;

    const cellToBeDeleteDigitNo = parseInt(colIdTODelete.match(/\d+/)[0]);

    tempRowsData.map((item, index) => {
      const orgData = { ...item };
      [...Array(columns?.length).keys()].map((index) => {
        if (index + 1 >= cellToBeDeleteDigitNo) {
          orgData[`col${index + 1}`] = item[`col${index + 2}`];
        }
      });

      newRowsData.push(orgData);
    });
    setRowsData([...newRowsData]);

    columnsFromState.pop();

    setColumns([...columns]);
    setColumnsCount((prevCount) => prevCount - 1);
    if (!anyOperationPerformedOnEntirePage.current) {
      anyOperationPerformedOnEntirePage.current = true;
    }
    setSkipPageReset(true);
  };

  const addRowsInBetween = (id, pos) => {
    setSkipPageReset(true);
    if (pos === "above") {
      rowsData.splice(parseInt(id), 0, {});
    } else if (pos === "below") {
      let index = parseInt(id) + 1;
      rowsData.splice(index, 0, {});
    }
    setRowsData([...rowsData]);
    if (!anyOperationPerformedOnEntirePage.current) {
      anyOperationPerformedOnEntirePage.current = true;
    }
  };

  const deleteRow = (rowIdToDelete) => {
    const data = rowsData;
    data.splice(parseInt(rowIdToDelete), 1);
    setRowsData([...data]);
    if (!anyOperationPerformedOnEntirePage.current) {
      anyOperationPerformedOnEntirePage.current = true;
    }
    setSkipPageReset(true);
  };

  const updateDataUsingManuallyContextMenu = (
    rowIndex,
    columnId,
    dragToValues
  ) => {
    if (
      rowsData[rowIndex][columnId] &&
      rowsData[rowIndex][columnId]?.value &&
      rowsData[rowIndex][columnId]?.value !== undefined
    ) {
      let selectedCellvalueToFill = rowsData[rowIndex][columnId];
      let noOfSelectedCol = columnId.match(/\d+/)[0];

      if (dragToValues?.pos === "right") {
        const totColumnsFromState = columns;
        // finding remeaninng size col columns by extraction current col number
        const exceptCurrentColRemeaningColslength =
          totColumnsFromState.length - noOfSelectedCol;

        for (
          let i = 1;
          i <= Math.min(exceptCurrentColRemeaningColslength, dragToValues?.nos);
          i++
        ) {
          const nextColIdToFill = parseInt(noOfSelectedCol) + i;
          const nextColId = `col${nextColIdToFill}`;
          rowsData[rowIndex][nextColId] = selectedCellvalueToFill;
        }
        setRowsData([...rowsData]);
      }

      if (dragToValues?.pos === "left") {
        // finding remeaninng size col columns by extraction current col number
        const exceptCurrentColRemeaningColslength = noOfSelectedCol - 1;

        for (
          let i = 1;
          i <= Math.min(exceptCurrentColRemeaningColslength, dragToValues?.nos);
          i++
        ) {
          const prevColIdToFill = parseInt(noOfSelectedCol) - i;
          const prevColId = `col${prevColIdToFill}`;
          rowsData[rowIndex][prevColId] = selectedCellvalueToFill;
        }
        setRowsData([...rowsData]);
      }

      if (dragToValues?.pos === "above") {
        // in below func tme remained row is same as rowIndex because it shows up to yet remeaning rows
        for (let i = 1; i <= Math.min(rowIndex, dragToValues?.nos); i++) {
          const nextColIdToFill = parseInt(rowIndex) - i;
          rowsData[nextColIdToFill][columnId] = selectedCellvalueToFill;
        }
        setRowsData([...rowsData]);
      }

      if (dragToValues?.pos === "below") {
        const totRowsFromState = rowsData;

        const exceptCurrentRowRemeaningRowslength =
          totRowsFromState.length - 1 - rowIndex;

        for (
          let i = 1;
          i <= Math.min(exceptCurrentRowRemeaningRowslength, dragToValues?.nos);
          i++
        ) {
          const nextRowIdToFill = parseInt(rowIndex) + i;
          rowsData[nextRowIdToFill][columnId] = selectedCellvalueToFill;
        }
        setRowsData([...rowsData]);
      }
      if (!anyOperationPerformedOnEntirePage.current) {
        anyOperationPerformedOnEntirePage.current = true;
      }
    }
    setSkipPageReset(true);
  };

  // After data chagnes, we turn the flag back off
  // so that if data actually changes when we're not
  // editing it, the page is reset
  React.useEffect(() => {
    setSkipPageReset(false);
  }, [rowsData]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      setAlert(false);
    }

    setAlert(false);
  };

  const clearMappings = (parameterIndex, mappingIndex) => {
    let cellData = {
      ...rowsData[parameterIndex][mappingIndex],
      value: "",
      // tagName: "",
      tagName:
        role === "preProcessor"
          ? ""
          : selectedFilesDataForProUpdate?.tagName
          ? selectedFilesDataForProUpdate?.tagName
          : "",
      // pageNo: "",
      pageNo:
        role === "preProcessor"
          ? ""
          : selectedFilesDataForProUpdate?.pageNo >= 0
          ? selectedFilesDataForProUpdate?.pageNo
          : "",
      // fileNo: "",
      fileNo:
        role === "preProcessor"
          ? ""
          : selectedFilesDataForProUpdate?.fileNo >= 0
          ? selectedFilesDataForProUpdate?.fileNo
          : "",
      coordinates: [],
      coordinatesValues: [],
      // preProcessor: localStorage.userName,
      // preProcessedDate: moment(new Date()).format("DD-MMM-YYYY"),
      ...(role === "preProcessor" && {
        preProcessor: localStorage.userName,
        preProcessedDate: moment(new Date()).format("DD-MMM-YYYY"),
      }),
      ...(role === "processor" && {
        processor: localStorage.userName,
        processedDate: moment(new Date()).format("DD-MMM-YYYY"),
      }),
    };
    rowsData[parameterIndex][mappingIndex] = cellData;
    setRowsData([...rowsData]);
  };

  const handleGenerateQAReport = () => {
    dispatch(
      generateQAReport({
        parametersData: rowsData,
        batch_id: localStorage.batchId,
        next: false,
        extractionType: "free-form",
        columnsCount: columnsCount,
      })
    );
    handleCloseAlert();
  };

  const handleClickOpenAlert = () => {
    setOpenConfirmAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenConfirmAlert(false);
  };

  return (
    <Styles className="CNCTable">
      <Prompt
        when={anyOperationPerformedOnEntirePage.current}
        message="Changes that you made may not be saved."
      />
      {role !== "validator" && (
        <>
          <FormControl className="custom-row-columns">
            <Input
              inputRef={rowInputRef}
              type="number"
              inputProps={{
                min: "0",
              }}
              placeholder={"Add Rows"}
              id="row"
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  clickAddRowChange();
                }
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={(e) => clickAddRowChange()}
                    type="submit"
                  >
                    <AddCircleTwoToneIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>

          <FormControl className="custom-row-columns">
            <Input
              inputRef={colInputRef}
              type="number"
              inputProps={{
                min: "0",
              }}
              placeholder={"Add Columns"}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  clickAddColumnChange();
                }
              }}
              id="col"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={(e) => clickAddColumnChange()}
                  >
                    <AddCircleTwoToneIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          {role === "preProcessor" && (
            <Button onClick={resetData} className="reset-btn">
              Reset Data
            </Button>
          )}
          {role === "processor" && (
            // <IconButton aria-label="delete"  color="primary">
            //   <DescriptionOutlinedIcon />
            // </IconButton>

            <Button
              component="label"
              variant="contained"
              startIcon={<DescriptionOutlinedIcon />}
              onClick={handleClickOpenAlert}
            >
              QA Report
            </Button>

            // <Button onClick={handleClickOpenAlert} className="reset-btn">
            //   QA Report
            // </Button>
          )}

          {role === "processor" && (
            <Button onClick={resetDataApiCall} className="reset-btn">
              Reset Data
            </Button>
          )}
        </>
      )}
      <Table
        columns={columns}
        data={rowsData}
        updateMyData={updateMyData}
        setCellToUpdateToState={setCellToUpdateToState}
        skipPageReset={skipPageReset}
        // extra props

        addColInBetween={addColInBetween}
        deleteColumn={deleteColumn}
        addRowsInBetween={addRowsInBetween}
        deleteRow={deleteRow}
        updateDataUsingManuallyContextMenu={updateDataUsingManuallyContextMenu}
        anyOperationPerformedOnEntirePage={anyOperationPerformedOnEntirePage}
        // extra props

        showContextMenu={showContextMenu}
        isDisableOnChangeInput={isDisableOnChangeInput}
        isDisableOnBlur={isDisableOnBlur}
        role={role}
        //rejection
        rejectCell={rejectCell}
        deleteRejection={deleteRejection}
        markAsResolveByProcessor={markAsResolveByProcessor}
        handleRowRejection={handleRowRejection}
        deleteRowRejection={deleteRowRejection}
        reProcess={reProcess}
        reValidate={reValidate}
        isDataRejectedByProcessor={isDataRejectedByProcessor}
        isDataRejectedByValidator={isDataRejectedByValidator}
        onClickValidate={onClickValidate}
        markAsResolveRejection={markAsResolveRejection}
        isFullView={isFullView}
        clearMappings={clearMappings}
        entireBatchRejectedByPro={entireBatchRejectedByPro}
        entireBatchRejectedByVali={entireBatchRejectedByVali}
        reProcessEntireBatch={reProcessEntireBatch}
      />

      {isAlert && (
        <Snackbar
          open={true}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          key={"top" + "center"}
        >
          <Alert onClose={handleClose} className="error-alert" severity="error">
            <AlertTitle color="white">Validation Error</AlertTitle>
            Some field are not validated. please Validate them before continue.
          </Alert>
        </Snackbar>
      )}
      {openAuth && (
        <Authentication
          openAuth={openAuth}
          handleClose={handleCloseAuth}
          handleUserPermission={handleUserPermission}
        />
      )}
      {openSignPad && (
        <ModalSign
          openSignPad={openSignPad}
          handleEsignDialogBoxClose={handleCloseSignPad}
        ></ModalSign>
      )}
      {openConfirmAlert && (
        <Dialog open={openConfirmAlert} onClose={handleCloseAlert}>
          <DialogTitle>Confirm Action</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to generate the QA report?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseAlert}
              color="primary"
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              onClick={handleGenerateQAReport}
              color="primary"
              variant="contained"
            >
              Generate
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {loading ? <ProgressBar /> : ""}

      {snackbar?.open && snackbar?.severity === "success" && (
        <CustomizedSnackbars
          severity={snackbar?.severity}
          state={snackbar?.open}
          text={snackbar.message}
          handleClose={() => handleCloseSnackbar()}
        />
      )}
      {snackbar?.open && snackbar?.severity === "error" && (
        <CustomizedSnackbars
          severity={snackbar?.severity}
          state={snackbar?.open}
          text={snackbar.message}
          handleClose={() => handleCloseSnackbar()}
        />
      )}
    </Styles>
  );
});

CNCTableWrapper.defaultProps = {};

export default CNCTableWrapper;
