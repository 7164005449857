export const USER_LOGIN = "USER_LOGIN";
export const LOGIN_SUCCSS = "LOGIN_SUCCSS";
export const LOGIN_FAIL = "LOGIN_FAILR";
export const USER_LOGOUT = "USER_LOGOUT";
export const API_STATUS = "API_STATUS";
export const FORGOT_PASSWORD_STATUS = "FORGOT_PASSWORD_STATUS";
export const FORGOT_PASSWORD_LINK_STATUS = "FORGOT_PASSWORD_LINK_STATUS";
export const IS_LOGGED_IN_ALREADY = "IS_LOGGED_IN_ALREADY";
export const E_SIGN_UPLOAD_STATUS = "E_SIGN_UPLOAD_STATUS";
export const GET_E_SIGNATURE = "GET_E_SIGNATURE";
