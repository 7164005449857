import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material/styles";
import AventiorLogo from "../../assets/aventior_logo_color_rgb.jpg";
import "./header.scss";
import { useHistory } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import * as acts from "../../redux/auth/actions";
import { useDispatch } from "react-redux";
import ModalEsign from "../Modal/signature/ModalSign";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ProgressBar from "../../components/ProgressBar";
import { action } from "../../constants/action";
import { SwipeableDrawer } from "@mui/material";
import SettingDrawer from "../SettingsDrawer/SettingsDrawer";
export default function Header(props) {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openSignPad, setopenSignPad] = useState(false);
  const open = Boolean(anchorEl);
  const [openSettingDrawer, setOpenSettingDrawer] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(acts.userLogout());
    localStorage.clear();
    history.push("/");
    setAnchorEl(null);
  };

  const handleEsignDialogBoxOpen = () => {
    setLoader(false);
    setopenSignPad(true);
    setAnchorEl(null);
  };
  const handleEsignDialogBoxClose = () => {
    setopenSignPad(false);
    setAnchorEl(null);
  };
  const handleHome = () => {
    if (localStorage.getItem("userRole") === "pre-processor") {
      history.push("pre-processor-dashboard");
    }
    if (localStorage.getItem("userRole") === "processor") {
      history.push("processor-dashboard");
    }
    if (localStorage.getItem("userRole") === "validator") {
      history.push("validator-dashboard");
    }
  };

  const toggleDrawer = (value) => {
    setOpenSettingDrawer(value);
    setAnchorEl(null);
  };

  return (
    <ThemeProvider>
      <Box className="header">
        <AppBar position="static">
          <Toolbar className="toolBar">
            <img
              src={AventiorLogo}
              className="logo"
              onClick={() => handleHome()}
              alt="AventiorLogo"
            />
            <div className="vertical"></div>
            <Typography variant="subtitle1" className="HeaderProjectName">
              {action?.LOGO}
            </Typography>

            <Typography variant="subtitle1" margin={1}>
              {localStorage?.userName || "Oscar Thomsen"} (
              {localStorage?.userName
                ? localStorage?.userRole?.charAt(0).toUpperCase() +
                  localStorage?.userRole?.slice(1)
                : "Admin"}
              )
            </Typography>

            <IconButton
              size="medium"
              edge="start"
              // color="inherit"
              aria-label="menu"
              onClick={handleClick}
            >
              <MenuIcon
                id="demo-positioned-button"
                aria-controls={open ? "demo-positioned-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                className="user-profile-icon"
              />
            </IconButton>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={open}
              className="user-profile"
              onClose={(e) => handleCloseMenu(e)}
              MenuListProps={{
                "aria-labelledby": "demo-positioned-button",
              }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              {localStorage.getItem("userRole") === "pre-processor" ||
              localStorage.getItem("userRole") === "processor" ||
              localStorage.getItem("userRole") === "validator" ? (
                <MenuItem onClick={handleEsignDialogBoxOpen}>
                  {action.E_SIGN}
                </MenuItem>
              ) : (
                ""
              )}
              <MenuItem onClick={() => toggleDrawer(true)}>
                {action.SETTINGS}
              </MenuItem>
              <MenuItem onClick={handleLogout}>{action.LOGOUT}</MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
      </Box>
      {loader === true && <ProgressBar></ProgressBar>}
      {localStorage.getItem("userRole") === "pre-processor" ||
      localStorage.getItem("userRole") === "processor" ||
      localStorage.getItem("userRole") === "validator"
        ? openSignPad && (
            <ModalEsign
              openSignPad={openSignPad}
              handleEsignDialogBoxClose={handleEsignDialogBoxClose}
            ></ModalEsign>
          )
        : ""}

      <div>
        <React.Fragment key={"right"}>
          <SwipeableDrawer
            className="header-toggle"
            anchor={"right"}
            open={openSettingDrawer}
            onClose={(e) => toggleDrawer(false)}
            onOpen={(e) => toggleDrawer(true)}
            sx={{width:"300px"}}
          >
            <SettingDrawer onClose={() => toggleDrawer(false)} />
          </SwipeableDrawer>
        </React.Fragment>
      </div>
    </ThemeProvider>
  );
}
