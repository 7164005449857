import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import "../PopupModal.scss";
import { Box, Typography } from "@mui/material";
import SignatureCanvas from "react-signature-canvas";
import "./modalSign.scss";
import Button from "../../Controls/buttons/Button";
import { useDispatch, useSelector } from "react-redux";
import Paper from "@mui/material/Paper";
import { useHistory } from "react-router";
import CloseIcon from "@mui/icons-material/Close";
import {
  clearESighUploadStatue,
  saveUserSignature,
} from "../../../redux/auth/actions";
import CustomizedSnackbar from "../../snackbars/CustomizedSnackbars";
import { action } from "../../../constants/action";
import { messages } from "../../../constants/commonMessages";
import { notification } from "../../../constants/notificationMessages";
import * as acts from "../../../redux/auth/actions";
import ProgressBar from "../../ProgressBar";

const ModalSign = ({ openSignPad, handleEsignDialogBoxClose }) => {
  const dispatch = useDispatch();

  const userSignature = useSelector((state) => state?.auth?.esignature);
  const apiStatus = useSelector((state) => state?.auth?.apiStatus);

  const eSignatureStatus = useSelector(
    (state) => state?.auth?.eSignatureStatus
  );
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const [message, setMessage] = useState("");
  let signPad;
  const [trimmedDataURL, setTrimmedDataURL] = useState(null);

  useEffect(() => {
    dispatch(clearESighUploadStatue());
    dispatch(acts.getUserSignature());
    setError(false);
    setMessage("");
    setTrimmedDataURL(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (eSignatureStatus && eSignatureStatus !== undefined) {
      if (eSignatureStatus?.loader === false) {
        if (
          eSignatureStatus?.message !== undefined ||
          eSignatureStatus?.message !== ""
        ) {
          if (eSignatureStatus?.next === true) {
            setSuccess(true);
            setMessage(eSignatureStatus.message);
            setTimeout(() => {
              setTrimmedDataURL(null);
              handleEsignDialogBoxClose(false);
              setSuccess(false);
            }, 2000);
            dispatch(clearESighUploadStatue());
          }
        }
        if (
          eSignatureStatus?.message !== undefined &&
          eSignatureStatus?.message !== "" &&
          eSignatureStatus?.next === false
        ) {
          setError(true);
          setMessage(eSignatureStatus.message);
          setTimeout(() => {
            setError(false);
            setMessage("");
          }, 2000);
          dispatch(clearESighUploadStatue());
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eSignatureStatus]);

  useEffect(() => {
    if (userSignature) {
      setTrimmedDataURL(userSignature?.data?.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSignature?.data]);

  const clear = () => {
    signPad.clear();
  };

  const trim = (props) => {
    let imageData = signPad.toDataURL();
    if (!signPad.isEmpty()) {
      dispatch(saveUserSignature(imageData));
      imageData = null;
    } else {
      setError(true);
      setMessage(notification.signatureNotDrawn);
    }
  };
  return (
    <div className="E-sign-model">
      <Modal
        open={openSignPad}
        onClose={handleEsignDialogBoxClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-sign-box">
          <div></div>
          <Typography variant="h6" className="e-sing-title">
            {messages.E_SIGNATURE}
            <CloseIcon onClick={handleEsignDialogBoxClose} />
          </Typography>
          <Typography className="user-name" variant="caption1">
            {localStorage?.userName}
          </Typography>
          <div className="signatureContainer">
            <SignatureCanvas
              penColor="black"
              canvasProps={{
                height: 200,
                className: "signatureCanvas",
              }}
              ref={(ref) => {
                signPad = ref;
              }}
            />
            <div className="esignUploadedText">{action.DRAW}</div>
          </div>
          <div className="signatureOutputContainer">
            <Paper className="signatureOutput">
              {apiStatus?.loader ? (
                <ProgressBar transform="translate(-50%, -50%)" />
              ) : trimmedDataURL?.length > 0 ? (
                <img className="esignImage" src={trimmedDataURL} alt="esign_image" />
              ) : (
                <p>Please Upload Your Signature</p>
              )}

              <div className="esignUploadedText">{action.UPLOAD}</div>
            </Paper>
          </div>
          <Box className="e-sign-modsal-btn-wrap">
            <Button
              className="btn-clr"
              text="clear"
              size="small"
              onClick={clear}
              variant="outlined"
            ></Button>
            <Button
              className="btn-upload"
              text="Upload"
              size="small"
              variant="contained"
              onClick={trim}
            ></Button>
          </Box>
          {success && (
            <CustomizedSnackbar
              severity="success"
              state={success}
              text={message}
              handleClose={() => setSuccess(false)}
            />
          )}
          {error && (
            <CustomizedSnackbar
              severity="error"
              state={error}
              text={message}
              handleClose={() => setError(false)}
            />
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default ModalSign;
