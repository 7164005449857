import React, { useState } from "react";
import { useTable, usePagination } from "react-table";
import { Input, Menu, MenuItem, TextField } from "@mui/material";
import CellMenu from "../TemplateDriven/CellMenu";
import { useDispatch } from "react-redux";
import "./freeFrom.scss";
import { pageRedirection } from "../../../redux/processor/actions";
import { valiPageRedirection } from "../../../redux/validator/actions";
import { action } from "../../../constants/action";
import CellInfoMenu from "../TemplateDriven/CellInfoMenu";

// Create an editable cell renderer
const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
  updateDataUsingManuallyContextMenu,
  anyOperationPerformedOnEntirePage,
  showContextMenu,
  isDisableOnChangeInput,
  isDisableOnBlur,
  role,
  rejectCell,
  deleteRejection,
  markAsResolveByProcessor,
  reProcess,
  reValidate,
  isRowMarkedAsResolvedByProcessor,
  data,
  isDataRejectedByProcessor,
  isDataRejectedByValidator,
  setCellToUpdateToState,
  markAsResolveRejection,
  clearMappings,
  entireBatchRejectedByPro,
  entireBatchRejectedByVali,
  reProcessEntireBatch,
}) => {
  // We need to keep and update the state of the cell normally
  const initialPointers = {
    mouseX: null,
    mouseY: null,
  };
  const dispatch = useDispatch();

  const [value, setValue] = React.useState(initialValue);
  const [pointers, setPointers] = React.useState(initialPointers);
  const [dragToValues, setDragToValues] = useState(null);
  const [isOpemContextMenu, setIsOpemContextMenu] = useState(false);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    // disable when role is pre proceoosr
    updateMyData({ rowIndex: index, columnId: id, value }); // here index = rowIndex and id = columnId
  };

  const onCellClick = () => {
    if (role === "preProcessor" && !isDisabled()) {
      updateMyData({ rowIndex: index, columnId: id });
      setCellToUpdateToState({ rowIndex: index, columnId: id });
    } else if (role === "processor") {
      if (
        initialValue?.fileNo >= 0 &&
        initialValue?.pageNo >= 0 &&
        initialValue?.tagName
      ) {
        const { fileNo, tagName, pageNo, indexesOfCoordinates, coordinates } =
          initialValue;
        dispatch(
          pageRedirection({
            fileNo,
            tagName,
            pageNo,
            indexesOfCoordinates,
            coordinates,
          })
        );
      }
      if (!isDisabled()) {
        setCellToUpdateToState({ rowIndex: index, columnId: id });
      } else {
        setCellToUpdateToState({ rowIndex: null, columnId: null });
      }
    } else if (role === "validator") {
      if (
        initialValue?.fileNo >= 0 &&
        initialValue?.pageNo >= 0 &&
        initialValue?.tagName
      ) {
        const { fileNo, tagName, pageNo, indexesOfCoordinates, coordinates } =
          initialValue;
        dispatch(
          valiPageRedirection({
            fileNo,
            tagName,
            pageNo,
            indexesOfCoordinates,
            coordinates,
          })
        );
      }
    }
  };

  // for role == pro and vali role    // here we are showing actual values
  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    if (role === "processor" || role === "validator") {
      // temp show tagging as it is later show value
      let valueToShow = "";
      if (initialValue?.value) {
        valueToShow = initialValue?.value;
      } else {
        valueToShow = "";
      }
      setValue(valueToShow);
    }
    if (role === "preProcessor") {
      let valueToShow = "";
      if (initialValue?.tagName && initialValue?.pageNo >= 0) {
        const pageNo = initialValue?.pageNo + 1; // added 1 because index starting from 0 and we want to shoe as 1
        valueToShow = `${initialValue?.tagName} - ${pageNo}`;
      } else {
        valueToShow = "";
      }
      setValue(valueToShow);
    }
  }, [initialValue?.value, initialValue?.pageNo, initialValue?.tagName, role]);

  const handleClick = (event) => {
    event.preventDefault();
    setPointers({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
    setDragToValues(null);
    setIsOpemContextMenu(true);
  };

  const handleCloseMenu = () => {
    setPointers(initialPointers);
    setDragToValues(null);
    setIsOpemContextMenu(false);
    // dragToValues?.nos &&
    //   updateDataUsingManuallyContextMenu(index, id, dragToValues);
  };

  const onEntered = () => {
    setPointers(initialPointers);
    dragToValues?.nos &&
      updateDataUsingManuallyContextMenu(index, id, dragToValues);
  };

  const dragInTo = (pos, value) => {
    setDragToValues({
      pos: pos,
      nos: value,
    });
  };

  const isDisabled = () => {
    let isDisabled = false;
    if (role === "preProcessor") {
      if (!entireBatchRejectedByPro) {
        if (isDataRejectedByProcessor) {
          if (
            initialValue?.processorCellRejection?.isRejected ||
            data[index]?.processorRowRejection?.isRejected
          ) {
            isDisabled = false;
          } else isDisabled = true;
        }
      }
    }

    if (role === "processor") {
      if (entireBatchRejectedByVali) {
        console.log("sss");
      }
      if (!entireBatchRejectedByVali) {
        if (!reProcessEntireBatch) {
          if (reProcess) {
            if (
              initialValue?.processorCellRejection?.isResolved ||
              data[index]?.processorRowRejection?.isResolved
            ) {
              isDisabled = false;
            } else isDisabled = true;
          }
        }

        if (isDataRejectedByValidator) {
          isDisabled =
            initialValue?.validatorCellRejection?.isRejected ||
            data[index]?.validatorRowRejection?.isRejected
              ? false
              : true;
        }

        if (reProcess && isDataRejectedByValidator) {
          // when data rejected by validator and processor and then resolved by pre processor at that time for both rejection we need to enable field
          let disableForProResolve = !(
            initialValue?.processorCellRejection?.isResolved === true ||
            data[index]?.processorRowRejection?.isResolved
          );
          let disableForValidatorRejection =
            initialValue?.validatorCellRejection?.isRejected ||
            data[index]?.validatorRowRejection?.isRejected
              ? false
              : true;

          if (
            disableForProResolve === false &&
            disableForValidatorRejection === true
          ) {
            isDisabled = false;
          }
        }
      }
    }
    if (role === "validator") {
      isDisabled = true;
      if (reValidate) {
        isDisabled = !(
          initialValue?.validatorCellRejection?.isResolved === true ||
          data[index]?.validatorRowRejection?.isResolved
        );
      } else isDisabled = false;
    }
    return isDisabled;
  };

  const styleRejectedCell = () => {
    let backgroundColor = "var(--bg-white)";

    if (role === "preProcessor") {
      if (
        initialValue?.processorCellRejection?.isRejected ||
        data[index]?.processorRowRejection?.isRejected
      ) {
        backgroundColor = "rgb(255 137 116)"; // fent red
      }
      if (
        initialValue?.processorCellRejection?.isRejected &&
        data[index]?.processorRowRejection?.isRejected
      ) {
        // backgroundColor = "rgb(253 185 209)";
        backgroundColor = "rgb(253 97 71)";
        // dark red
      }
    }

    if (role === "processor") {
      // if cell or row rej in vali  or pro
      if (
        initialValue?.processorCellRejection?.isRejected ||
        data[index]?.processorRowRejection?.isRejected ||
        initialValue?.validatorCellRejection?.isRejected ||
        data[index]?.validatorRowRejection?.isRejected
      ) {
        // backgroundColor = "#ff8686";
        backgroundColor = "rgb(255 137 116)";
        // fent red
      }
      // if cell and row rej in pro
      if (
        initialValue?.processorCellRejection?.isRejected &&
        data[index]?.processorRowRejection?.isRejected
      ) {
        // backgroundColor = "rgb(253 185 209)";
        backgroundColor = "rgb(253 97 71)";
        // dark red
      }
      // if cell and row rej in vali
      if (
        initialValue?.validatorCellRejection?.isRejected &&
        data[index]?.validatorRowRejection?.isRejected
      ) {
        // backgroundColor = "rgb(253 185 209)";
        backgroundColor = "rgb(253 97 71)";
        // dark red
      }
      // if cell and row rej in vali ans coll or row rej in pro

      if (
        (initialValue?.validatorCellRejection?.isRejected ||
          data[index]?.validatorRowRejection?.isRejected) &&
        (initialValue?.processorCellRejection?.isRejected ||
          data[index]?.processorRowRejection?.isRejected)
      ) {
        // backgroundColor = "rgb(246 141 177)";
        backgroundColor = "rgb(255 75 46)";
        // 2* dark red
      }
      if (
        initialValue?.validatorCellRejection?.isRejected &&
        data[index]?.validatorRowRejection?.isRejected &&
        initialValue?.processorCellRejection?.isRejected &&
        data[index]?.processorRowRejection?.isRejected
      ) {
        backgroundColor = "rgb(255 75 46)"; // 3* dark red
      }
    }

    if (role === "validator") {
      if (
        initialValue?.validatorCellRejection?.isRejected ||
        data[index]?.validatorRowRejection?.isRejected
      ) {
        // backgroundColor = "#ff8686";
        backgroundColor = "rgb(255 137 116)";
        // fent red
      }
      if (
        initialValue?.validatorCellRejection?.isRejected &&
        data[index]?.validatorRowRejection?.isRejected
      ) {
        // backgroundColor = "rgb(253 185 209)";
        backgroundColor = "rgb(253 97 71)";
        // dark red
      }
      if (data[index]?.rowValidation?.isValidated) {
        backgroundColor = "#e5ffdb";
      }
    }

    return { backgroundColor: backgroundColor };
  };

  const onCero = (e) => {
    if (e.keyCode === 8 || e.keyCode === 46) {
      // key code added for backspace and delete
      clearMappings(index, id);
    }
  };

  return (
    <div
      className="reject-resould-btn"
      onContextMenu={showContextMenu && handleClick}
      style={styleRejectedCell()}
    >
      <Input
        // onKeyDown={(e) => role === "preProcessor" && onCero(e)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault(); // Prevent the default Enter key behavior
          }
          if (role === "preProcessor") {
            onCero(e); // Handle other specific logic for the preProcessor role
          }
        }}
        // id={index}
        className={role === "validator" ? "disable_opacity" : ""}
        type="string"
        variant="outlined"
        onChange={!isDisableOnChangeInput && onChange}
        onBlur={!isDisableOnBlur && onBlur}
        onClick={(event) => onCellClick()}
        contentEditable={true}
        value={value !== undefined ? value : ""}
        multiline={true}
        suppressContentEditableWarning={true}
        disabled={isDisabled()}
      ></Input>

      {role === "preProcessor" &&
      !initialValue?.processorCellRejection?.isRejected ? (
        initialValue?.coordinatesValues &&
        initialValue?.coordinatesValues.length > 0 && (
          <CellInfoMenu
            coordinatesValues={initialValue?.coordinatesValues}
            clearMappings={() => clearMappings(index, id)}
          />
        )
      ) : (
        <div style={{}}>
          <CellMenu
            isRejectedByProcessor={
              initialValue?.processorCellRejection?.isRejected
            }
            processorRejectionRemark={
              initialValue?.processorCellRejection?.remark
            }
            isRejectedByValidator={
              initialValue?.validatorCellRejection?.isRejected
            }
            validatorRejectionRemark={
              initialValue?.validatorCellRejection?.remark
            }
            rejectCell={(rejectionRemark) =>
              rejectCell({
                rejectionRemark,
                rowIndex: index,
                columnId: id,
              })
            }
            deleteRejection={() =>
              deleteRejection({ rowIndex: index, columnId: id })
            }
            role={role}
            markAsResolveByProcessor={() =>
              markAsResolveByProcessor({ rowIndex: index, columnId: id })
            }
            isRwoValidated={data[index]?.rowValidation?.isValidated}
            markAsResolveRejection={() =>
              markAsResolveRejection({ rowIndex: index, columnId: id })
            }
            heading={
              role === "preProcessor"
                ? action.REJECTION_REMARK
                : action.REJECTION_PROMPT
            }
          />
          {initialValue?.coordinatesValues &&
            initialValue?.coordinatesValues.length > 0 &&
            role !== "validator" && (
              <CellInfoMenu
                coordinatesValues={initialValue?.coordinatesValues}
                clearMappings={() => clearMappings(index, id)}
              />
            )}
        </div>
      )}

      {showContextMenu && isOpemContextMenu && (
        <Menu
          className="context-menu"
          // id={`simple-menu-${index}`}
          PaperProps={{
            style: {
              width: 220,
            },
          }}
          keepMounted
          open={pointers.mouseY !== null}
          onClose={handleCloseMenu}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              onEntered();
            }
          }}
          anchorReference="anchorPosition"
          anchorPosition={
            pointers.mouseY !== null && pointers.mouseX !== null
              ? { top: pointers.mouseY, left: pointers.mouseX }
              : undefined
          }
        >
          <MenuItem>
            <span className="copy-label">Copy to below</span>
            <TextField
              className="mx-5"
              type="number"
              // id={index}
              inputProps={{
                style: {
                  fontSize: "15px",
                  width: "35px",
                  padding: "5px",
                },
                min: "0",
              }}
              value={
                dragToValues?.pos == "below" && dragToValues?.nos
                  ? dragToValues?.nos
                  : ""
              }
              disabled={dragToValues !== null && dragToValues?.pos !== "below"}
              onChange={(e) => dragInTo("below", e.target.value)}
            />
            cells
          </MenuItem>
          <MenuItem>
            <span className="copy-label">Copy to above</span>
            <TextField
              className="mx-5"
              type="number"
              // id={index}
              inputProps={{
                style: {
                  fontSize: "15px",
                  width: "35px",
                  padding: "5px",
                },
                min: "0",
              }}
              value={
                dragToValues?.pos == "above" && dragToValues?.nos
                  ? dragToValues?.nos
                  : ""
              }
              disabled={dragToValues !== null && dragToValues?.pos !== "above"}
              onChange={(e) => dragInTo("above", e.target.value)}
            />
            cells
          </MenuItem>{" "}
          <MenuItem>
            <span className="copy-label">Copy to right</span>
            <TextField
              className="mx-5"
              type="number"
              // id={index}
              inputProps={{
                style: {
                  fontSize: "15px",
                  width: "35px",
                  padding: "5px",
                },
                min: "0",
              }}
              value={
                dragToValues?.pos == "right" && dragToValues?.nos
                  ? dragToValues?.nos
                  : ""
              }
              disabled={dragToValues !== null && dragToValues?.pos !== "right"}
              onChange={(e) => dragInTo("right", e.target.value)}
            />
            cells
          </MenuItem>{" "}
          <MenuItem>
            <span className="copy-label">Copy to left</span>
            <TextField
              className="mx-5"
              type="number"
              // id={index}
              inputProps={{
                style: {
                  fontSize: "15px",
                  width: "35px",
                  padding: "5px",
                },
                min: "0",
              }}
              value={
                dragToValues?.pos == "left" && dragToValues?.nos
                  ? dragToValues?.nos
                  : ""
              }
              disabled={dragToValues !== null && dragToValues?.pos !== "left"}
              onChange={(e) => dragInTo("left", e.target.value)}
            />
            cells
          </MenuItem>
        </Menu>
      )}
    </div>
  );
};

export default EditableCell;
