import React, { useEffect, useState } from "react";
import Header from "../../../components/header/Header";
import ProjectHeader from "../../../components/header/ProjectHeader";
import Footer from "../../../components/Footer/index";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import DaysCard from "../../../components/Card/DaysCard";
import { Typography } from "@mui/material";
import * as acts from "../../../redux/pre_processor/actions";
import { useDispatch, useSelector } from "react-redux";
import BatchStepper from "../../../components/Stepper/BatchStepperCpvAuto";
import CustomizedSnackbar from "../../../components/snackbars/CustomizedSnackbars";
import Progressbar from "../../../components/ProgressBar";
import { useHistory } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import "./UploadPdfAgainstBatch.scss";
import ConstantValues from "../../../constants/ConstantValues";

const theme = createTheme({
  typography: {
    subtitle1: {
      fontSize: 16,
      fontWeight: 600,
      color: "#2D2D2D",
      // fontFamily: var(--font-family),
    },
  },
});

const UploadPdfAgainstBatch = () => {
  const dispatch = useDispatch();
  const tags = useSelector((state) => state?.prePro?.tags);
  const uploadedBatchPdfs = useSelector(
    (state) => state?.prePro?.uploadedBatchPdfs
  );
  const [checked, setChecked] = React.useState(true);
  const [actualFilesListData, setActualFilesListData] = useState({
    loading: false,
    error: false,
    errorMsg: "",
    data: [],
  });
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadPdfSucces, setuploadPdfSuccess] = useState(false);
  const [uploadPdfError, setUploadPdfError] = useState(false);
  const [footerDisable, setFooterDisable] = useState(true);
  const [load, setLoad] = useState(true);
  const [message, setMessage] = useState("");
  const fileType = "actual_pdfs";
  const subFolder = localStorage.drugType;
  // let selectedFiles = [];
  const { TEXT_EXTRACTION } = ConstantValues;

  useEffect(() => {
    dispatch(acts.getTags(localStorage.projectId));
    dispatch(acts.getTagsAndPdf(localStorage.projectId, localStorage.batchId));
    dispatch(acts.getS3Files(fileType, subFolder));
  }, []);

  const apiStatus = useSelector((state) => state?.prePro?.apiStatus);
  const history = useHistory();
  const drugType = useSelector((state) => state?.prePro?.drugType);
  useEffect(() => {
    if (apiStatus && apiStatus !== undefined) {
      if (apiStatus?.loader === false) {
        if (apiStatus?.messages != undefined) {
          setMessage(apiStatus?.messages);
          if (apiStatus?.next === true) {
            setuploadPdfSuccess(true);
            setTimeout(() => {
              history.push("/pre-processor-dashboard");
            }, 2000);
            dispatch(acts.apiStatusClear());
          } else if (apiStatus?.next === false && apiStatus?.message != null) {
            setUploadPdfError(true);
            setMessage(apiStatus.message);
          }
        }
      }
    }

    if (drugType && drugType.length > 0 && drugType) {
      setActualFilesListData({
        loading: false,
        error: false,
        errorMsg: "",
        data: drugType,
      });
    }
  }, [apiStatus, drugType]);
  const handleChange = (index, pdfName, pdfPath, tagName) => {
    setFooterDisable(false);
    selectedFiles[index] = {
      tagName: tagName,
      pdfPath: pdfPath,
      pdfName: pdfName,
    };
    setSelectedFiles(selectedFiles);
  };
  const handleCheckBoxClick = (event) => {
    setChecked(event.target.checked);
  };
  const handleClick = (event, action) => {
    if (action === "previous") {
      history.push("/pre-processor-dashboard");
    } else if (action === "save") {
      const next = false;
      dispatch(
        acts.uploadBatchPdfs(
          selectedFiles,
          localStorage.projectId,
          localStorage.batchId,
          checked,
          next
        )
      );
    } else if (action === "continue") {
      const next = true;
      dispatch(
        acts.uploadBatchPdfs(
          selectedFiles,
          localStorage.projectId,
          localStorage.batchId,
          checked,
          next
        )
      );
    }
  };
  useEffect(() => {
    // set here the uploaded pdf to state variable which is "selectedFiles"
    let files = [];
    if (uploadedBatchPdfs?.length > 0) {
      uploadedBatchPdfs.map((item) => {
        files.push({
          pdfPath: item.pdfpath,
          pdfName: item.original_filename,
          tagName: item.tagname,
        });
      });

      setSelectedFiles(files);
      setFooterDisable(false);
      setLoad(false);
    } else {
      setLoad(false);
    }
  }, [uploadedBatchPdfs]);
  useEffect(() => {
    setLoad(true);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Header />
        <BatchStepper currentStep={1} />
        <ProjectHeader
          position="center"
          headerColor="#F5F5F5"
          text={localStorage.projectName + "-" + localStorage.batchName}
        />
        <Box
        className="uploadpdf-main-container"
         >
          {!load && tags && tags?.data?.tags?.length > 0 && (
            <Typography variant="subtitle1" className="tag-style">
              Tags ({tags && tags?.data?.tags?.length})
            </Typography>
          )}
          <Box 
          className="uploadpdf-card-container"
          >
            <Grid container spacing={3}>
              {load ? (
                <Progressbar />
              ) : (
                tags &&
                tags?.data?.tags?.length > 0 &&
                tags?.data?.tags?.map((tagName, index) => {
                  return (
                    <Grid item xs={2} sm={4} md={4}>
                      <DaysCard
                        tagName={tagName}
                        actualFilesListData={actualFilesListData}
                        handleChange={handleChange}
                        cardIndex={index}
                        selectedFiles={selectedFiles}
                        uploadedBatchPdfs={uploadedBatchPdfs}
                      />
                    </Grid>
                  );
                })
              )}

            </Grid>
            {!load && tags && tags?.data?.tags?.length > 0 && (
              <div className="checkBox">
                <Checkbox
                  disabled={localStorage.ocrReInitiate === "true" ? true : false}
                  checked={checked}
                  onChange={handleCheckBoxClick}
                  inputProps={{ "aria-label": "controlled" }}
                />
                {TEXT_EXTRACTION}
              </div>
            )}
          </Box>

        </Box>
        <Footer
          isPrevious={false}
          isNextDisable={footerDisable !== true ? false : true}
          isPreviousDisable={true}
          isSaveDisable={footerDisable !== true ? false : true}
          onHandleClick={(event, action) => handleClick(event, action)}
        />
        {actualFilesListData.error && (
          <CustomizedSnackbar
            severity="error"
            state={actualFilesListData.error}
            text={message}
            handleClose={() => setActualFilesListData.error(false)}
          />
        )}
        {uploadPdfSucces && (
          <CustomizedSnackbar
            severity="success"
            state={uploadPdfSucces}
            text={message}
            handleClose={() => setuploadPdfSuccess(false)}
          />
        )}

        {apiStatus.loader && <Progressbar />}
      </div>
    </ThemeProvider>
  );
};
export default UploadPdfAgainstBatch;
